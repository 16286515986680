<template>
  <a-modal
    id="schedule-recording-modal"
    v-model:visible="showSchedule"
    title="Schedule Recording"
    ok-text="Schedule"
    :mask-closable="false"
    :ok-button-props="{ id: 'schedule-recording-ok-btn' }"
    :cancel-button-props="{
      id: 'schedule-recording-cancel-btn',
      style: { marginLeft: 'auto' }
    }"
    @ok="scheduleRecording"
  >
    <template #closeIcon>
      <close-outlined id="schedule-recording-modal-close-btn" />
    </template>
    <a-spin v-if="isScheduling" />
    <div v-else>
      <a-select
        id="select-schedule-days-input"
        v-model:value="selectedDays"
        class="w-100"
        mode="multiple"
        placeholder="Select day(s)"
        :options="daysOptions"
      />
      <a-time-range-picker
        id="select-schedule-time-input"
        v-model:value="startEndTime"
        class="w-100 mt-3"
        format="HH:mm"
        style="width: 400px"
      />
    </div>
  </a-modal>

  <a-table
    id="record-data-devices-table"
    :columns="columns"
    :data-source="activeDevices"
    :loading="isLoadingDevices"
    :scroll="{ x: true }"
    class="w-100"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <a-typography-title :level="5">
        Device(s) Active: &nbsp;
        <a-tag id="record-active-devices-count" color="blue">
          {{ activeDevicesCount }}
        </a-tag>
      </a-typography-title>
    </template>

    <template #emptyText>
      <div
        id="no-active-devices-found-record-data"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2">No Active Device(s) Found</span>
      </div>
    </template>

    <template
      :id="'rd-device-' + title + '-header-cell'"
      #headerCell="{ title }"
    >
      <span class="text-center">{{ title }}</span>
    </template>

    <template #bodyCell="{ column, record, index }">
      <div>
        <div v-if="column.dataIndex === 'deviceId'" class="text-center">
          {{ record.display_name || record.Serial_number }}
        </div>

        <div v-if="column.dataIndex === 'status'" class="text-center">
          <!-- Recording -->
          <a-tag
            v-if="
              record.record_shift ||
                (record.is_shift_managed && record.recording)
            "
            :id="'rd-device-' + index + '-recording-status'"
            color="error"
          >
            <template #icon>
              <video-camera-outlined />
            </template>
            Recording
          </a-tag>

          <!-- Uploading -->
          <div v-else-if="record?.uploading">
            <a-tag
              :id="'rd-device-' + index + '-uploading-status'"
              color="processing"
            >
              <template #icon>
                <cloud-upload-outlined />
              </template>
              Uploading
            </a-tag>
            <a-tag v-if="record?.uploading?.name" color="success">
              {{ record?.uploading?.name }}
            </a-tag>
            <a-progress
              :id="'rd-device-' + index + '-uploading-progress'"
              type="circle"
              :percent="record?.uploading?.progress"
              :width="40"
            />
          </div>

          <!-- Downloading -->
          <div v-else-if="record?.downloading">
            <a-tag
              :id="'rd-device-' + index + '-downloading-tag'"
              color="processing"
            >
              <template #icon>
                <cloud-upload-outlined />
              </template>
              Downloading
            </a-tag>
            <a-tag color="success">
              {{ record?.downloading?.name }}
            </a-tag>
            <a-progress
              :id="'rd-device-' + index + '-downloading-progress'"
              type="circle"
              :percent="record?.downloading?.progress"
              :width="40"
            />
          </div>

          <a-tag v-else>
            None
          </a-tag>
        </div>

        <div v-if="column.dataIndex === 'task'" class="text-center">
          <a-select
            :id="'rd-device-' + index + '-select-task'"
            :ref="el => (taskInputRefs[record.Serial_number] = el)"
            show-search
            class="w-100"
            :value="record.Task"
            placeholder="Select Operation"
            :options="taskOptions"
            :loading="isLoadingTasks"
            :disabled="record?.record_shift"
            :filter-option="filterOption"
            @change="val => handleChangeTask(record, val)"
          />
        </div>

        <div v-if="column.dataIndex === 'action'" class="text-center">
          <a-button
            :id="'rd-active-device-record-btn-' + index"
            :disabled="!record.Task"
            block
            type="primary"
            :loading="isRecordingLoading[record.id]"
            @click="handleRecordingBtn(record)"
          >
            {{ record?.record_shift ? 'Stop' : 'Start' }}
            Recording
          </a-button>
          <a-button
            :id="'rd-active-device-schedule-button-' + index"
            block
            type="primary"
            class="mt-1"
            :disabled="!record.Task"
            :loading="isScheduleRecordingLoading[record.id]"
            @click="handleScheduleRecording(record, 'recording')"
          >
            Schedule Recording
          </a-button>
        </div>
      </div>
    </template>
  </a-table>
</template>

<script>
import spaceMixin from 'src/mixins/handleSpace';
import daysOptions from 'src/config/days-config.js';
import columns from 'src/config/columns-config.js';
import { useActiveDevices } from '../composables/useActiveDevices';

import {
  CloudUploadOutlined,
  VideoCameraOutlined,
  LaptopOutlined,
  CloseOutlined
} from '@ant-design/icons-vue';

export default {
  components: {
    LaptopOutlined,
    CloudUploadOutlined,
    VideoCameraOutlined,
    CloseOutlined
  },
  mixins: [spaceMixin],
  setup() {
    const {
      activeDevicesList,
      activeDevices,
      showScheduleModal,
      scheduleRecording,
      isScheduling,
      selectedDays,
      startEndTime,
      isLoadingDevices,
      activeDevicesCount,
      updateDeviceSettings,
      handleRecording,
      showSchedule,
      taskInputRefs,
      taskOptions,
      isLoadingTasks,
      handleChangeTask
    } = useActiveDevices();

    return {
      columns,
      handleChangeTask,
      activeDevicesList,
      activeDevices,
      showScheduleModal,
      scheduleRecording,
      isScheduling,
      selectedDays,
      startEndTime,
      showSchedule,
      isLoadingDevices,
      activeDevicesCount,
      taskInputRefs,
      updateDeviceSettings,
      handleRecording,
      daysOptions,
      taskOptions,
      isLoadingTasks
    };
  },
  data() {
    return {
      isRecordingLoading: {},
      isScheduleRecordingLoading: {}
    };
  },
  methods: {
    async handleRecordingBtn(record) {
      const index = record.id;
      this.isRecordingLoading[index] = true;
      await this.handleRecording(record);
      this.isRecordingLoading[index] = false;
    },

    handleScheduleRecording(record, action) {
      const index = record.id;
      this.isScheduleRecordingLoading[index] = true;
      this.showScheduleModal(record, action);
      this.isScheduleRecordingLoading[index] = false;
    }
  }
};
</script>
